import moment from "moment";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { getFieldRegexp } from "./ValidationRegex";

const phoneUtil = PhoneNumberUtil.getInstance();

const hasTerminals = (application) => {
  return application.equipment.some(item => item.EquipmentTypeID === 'TM');
}

export const validateLead = lead => {
  let isValid = true;
  let errors = {};

  const validateTextField = (field, message) => {
    if (!lead[field] || lead[field] === "") {
      isValid = false;
      errors[field] = message;
    }
  };

  const validatePhone = (field, message) => {
    if (lead[field]) {
      try {
        const number = phoneUtil.parseAndKeepRawInput(lead[field], "US");
        if (!phoneUtil.isValidNumberForRegion(number, "US")) {
          isValid = false;
          errors[field] = message;
        } else {
          lead[field] = phoneUtil.format(number, PhoneNumberFormat.E164);
        }
      } catch {
        isValid = false;
        errors[field] = message;
      }
    }
  };  

  validateTextField("source", "Source is Required");
  validateTextField("name", "Name is Required");
  validateTextField("dba", "DBA is Required");
  validateTextField("phone", "Phone is Required");
  validatePhone("phone", "Phone is in an Incorrect Format");
  validatePhone("phone2", "Phone is in an Incorrect Format");

  return { isValid, lead, errors };
};

export const validateStep = (activeStep, application, signaturePad) => {
  let isValid = true;
  let errors = {};
  const validateTextField = (field, message) => {
    if (!application[field] || application[field] === "") {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateCustomPricingField = (field, message) => {
    if (!application['customPricing'][field] || application['customPricing'][field] === "") {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateTextLength = (field, length, message) => {
    if (application[field] && application[field].length !== length) {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateTextMatch = (field1, field2, message) => {
    if (
      application[field1]?.toLowerCase() !== application[field2]?.toLowerCase()
    ) {
      isValid = false;
      errors[field2] = message;
    }
  };
  const validateMinimumNumber = (field, minimum, message) => {
    if (!application[field] || application[field] <= minimum) {
      isValid = false;
      errors[field] = message;
    }
  };  
  const validateMaximumNumber = (field, maximum, message) => {
    if (!application[field] || application[field] > maximum) {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateMinimumCharLength = (field, minimum, message) => {
    if (!application[field] || application[field].length + 1 <= minimum) {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateDateField = (field, message) => {
    const textDate = application[field];
    const fieldMoment = moment(textDate, "MM-DD-YYYY");
    if (textDate && (textDate.length !== 10 || !fieldMoment.isValid())) {
      isValid = false;
      errors[field] = message;
    }
  };
  const validateRegEx = (field, message, pattern) => {
    if (application[field] && !application[field].match(pattern)) {
      isValid = false;
      errors[field] = message;
    }
  };
  const validatePhone = (field, message) => {
    if (application[field]) {
      try {
        const number = phoneUtil.parseAndKeepRawInput(application[field], "US");
        if (!phoneUtil.isValidNumberForRegion(number, "US")) {
          isValid = false;
          errors[field] = message;
        } else {
          application[field] = phoneUtil.format(number, PhoneNumberFormat.E164);
        }
      } catch {
        isValid = false;
        errors[field] = message;
      }
    }
  };  

  const runValidationEmail = () => {
    validateTextField("email", "Email Address is Required");
    validateRegEx(
      "email",
      "Email is an Incorrect Format",
      getFieldRegexp('email')
    );
    validateTextField("confirmEmail", "Email Address is Required");
    validateTextMatch(
      "email",
      "confirmEmail",
      "Email Addresses Must Match"
    );
  }

  const runValidationBusinessInfo = () => {
      // Website
      validateRegEx(
        "website",
        "Website address is an Incorrect Format",
        getFieldRegexp('website')
      );

      // Legal Business Name
      validateTextField(
        "legalBusinessName",
        "Legal Business Name is Required"
      );
      /*
      validateRegEx(
        "legalBusinessName",
        "Legal Business Name is not valid",
        getFieldRegexp('legalBusinessName')
      );
      */
      validateMinimumCharLength(
        "legalBusinessName",
        1,
        "Legal Business Name Must Be At Least 1 Character"
      );

      // Doing Business As
      validateMinimumCharLength(
        "doingBusinessAs",
        1,
        "Doing Business As Must Be At Least 1 Character"
      );
      /*
      validateRegEx(
        "doingBusinessAs",
        "Doing Business As is an Incorrect Format",
        getFieldRegexp('doingBusinessAs')
      );
      */
      validateTextField("doingBusinessAs", "Doing Business As is Required");

      // Business Description
      validateTextField(
        "businessDescription",
        "Business Description is Required"
      );

      //Ownership Type
      validateTextField("ownershipType", "Ownership Type is Required");
      if (application.ownershipType !== "Sole Proprietorship") {
        validateTextField("federalTaxId", "Federal Tax ID is Required");
        validateTextLength(
          "federalTaxId",
          10,
          "Federal Tax ID is an Incorrect Format"
        );
        validateRegEx(
          "federalTaxId",
          "Federal Tax ID is an Incorrect Format",
          getFieldRegexp('federalTaxId')
        );
      }

      // Business Year
      validateTextField("businessYear", "Business Year is Required");
      validateTextLength(
        "businessYear",
        4,
        "Business Year is an Incorrect Format"
      );

      //Business phone
      validateTextField("businessPhone", "Phone Number is Required");
      validateTextLength(
        "businessPhone",
        12,
        "Phone Number is an Incorrect Format"
      );
      validateRegEx(
        "businessPhone",
        "Phone Number is an Incorrect Format",
        getFieldRegexp('phone')
      );

      // Business Address
      validateTextField("businessAddress1", "Business Address 1 is Required");

      // Business city
      validateTextField("businessCity", "City is Required");
      validateRegEx(
        "businessCity",
        "City is an Incorrect Format",
        getFieldRegexp('city')
      );

      // Business state
      validateTextField("businessState", "State is Required");
      validateMinimumCharLength(
        "businessState",
        2,
        "State Must be 2 Characters"
      );
      validateRegEx(
        "businessState",
        "State is an Incorrect Format",
        getFieldRegexp('state')
      );

      // Business zip
      validateTextField("businessZip", "Zip Code is Required");
      validateTextLength(
        "businessZip",
        5,
        "Business Zip Code is an Incorrect Format"
      );
      validateRegEx(
        "businessZip",
        "Zip is an Incorrect Format",
        getFieldRegexp('zip')
      );

      if (application.mailingDifferent) {
        validateTextField(
          "mailingAddress1",
          "Mailing Address is Required"
        );
      
        // mailing city
        validateTextField("mailingCity", "City is Required");
        validateRegEx(
          "mailingCity",
          "City is an Incorrect Format",
          getFieldRegexp('city')
        );
      
        // mailing state
        validateTextField("mailingState", "State is Required");
        validateMinimumCharLength(
          "mailingState",
          2,
          "State Must be 2 Characters"
        );
        validateRegEx(
          "mailingState",
          "State is an Incorrect Format",
          getFieldRegexp('state')
        );
      
        // mailing zip
        validateTextField("mailingZip", "Zip Code is Required");
        validateTextLength(
          "mailingZip",
          5,
          "Mailing Zip Code is an Incorrect Format"
        );
        validateRegEx(
          "mailingZip",
          "Zip is an Incorrect Format",
          getFieldRegexp('zip')
        );
      }      
  }

  const runValidationPaymentCartInfo = () => {
    if (application.withoutPayment) return;

    validateTextField("creditCardNumber", "Card Number is Required");
    validateTextField("creditCardType", "Card Type is Required");
    validateTextField("creditCardCvv", "CVV is Required");
    validateTextField(
      "creditCardExpireDate",
      "Expiration Date is Required"
    );
    validateTextLength(
      "creditCardExpireDate",
      5,
      "Expiration Date is an Incorrect Format"
    );
    validateTextField("billingName", "Billing Name is Required");

    //if (application.billingDifferent) {
      validateTextField(
        "billingAddress",
        "Billing Address is Required"
      );

      // billing city
      validateTextField("billingCity", "City is Required");
      validateRegEx(
        "billingCity",
        "City is an Incorrect Format",
        getFieldRegexp('city')
      );

      // billing state
      validateTextField("billingState", "State is Required");
      validateMinimumCharLength(
        "billingState",
        2,
        "State Must be 2 Characters"
      );
      validateRegEx(
        "billingState",
        "State is an Incorrect Format",
        getFieldRegexp('state')
      );

      // billing zip
      validateTextField("billingZip", "Zip Code is Required");
      validateTextLength(
        "billingZip",
        5,
        "Billing Zip Code is an Incorrect Format"
      );
      validateRegEx(
        "billingZip",
        "Zip is an Incorrect Format",
        getFieldRegexp('zip')
      );
    //}
  }

  const runValidationPrincipalInfo = () => {
    // First Name
    validateTextField("firstName", "First Name is Required");
    validateRegEx(
      "firstName",
      "First Name is an Incorrect Format",
      getFieldRegexp('firstName')
    );

    // Last Name
    validateTextField("lastName", "Last Name is Required");
    validateRegEx(
      "lastName",
      "Last Name is an Incorrect Format",
      getFieldRegexp('lastName')
    );

    // Date of Birth
    validateTextField("dateOfBirth", "Date of Birth is Required");
    validateDateField(
      "dateOfBirth",
      "Date of Birth is an Incorrect Format"
    );
    validateRegEx(
      "dateOfBirth",
      "Date of Birth is an Incorrect Format",
      getFieldRegexp('dateOfBirth')
    );

    // Social Security Number
    if (!application.encryptedSocialSecurityNumber) {
      if (application.socialSecurityNumber) {
        validateTextLength(
          "socialSecurityNumber",
          11,
          "Social Security Number is an Incorrect Format"
        );
      }
      else {
        validateTextField(
          "socialSecurityNumber",
          "Social Security Number is Required"
        );
      }
    }

    // Home Phone Number
    validateTextField("homePhone", "Phone Number is Required");
    validateTextLength(
      "homePhone",
      12,
      "Phone Number is an Incorrect Format"
    );
    validateRegEx(
      "homePhone",
      "Phone Number is an Incorrect Format",
      getFieldRegexp('phone')
    );

    if (application.residenceDifferent) {
      // Home Address
      validateTextField("homeAddress1", "Home Address 1 is Required");

      // Home city
      validateTextField("homeCity", "City is Required");
      validateRegEx(
        "homeCity",
        "City is an Incorrect Format",
        getFieldRegexp('city')
      );

      // Home state
      validateTextField("homeState", "State is Required");
      validateMinimumCharLength(
        "homeState",
        2,
        "State Must be 2 Characters"
      );
      validateRegEx(
        "homeState",
        "State is an Incorrect Format",
        getFieldRegexp('state')
      );

      // Home zip
      validateTextField("homeZip", "Zip Code is Required");
      validateTextLength(
        "homeZip",
        5,
        "Home Zip Code is an Incorrect Format"
      );
      validateRegEx(
        "homeZip",
        "Zip is an Incorrect Format",
        getFieldRegexp('zip')
      );
    }
  }

  const runValidationAccountInfo = () => {
    // Average Sales
    validateMinimumNumber(
      "averageSale",
      0,
      "Average Sales Amount Must be Greater than $0"
    );
    validateMaximumNumber(
      "averageSale",
      50000,
      "Average Sales Amount Must be no more than $50000"
    );

    // Highest Estimated Sales
    validateMinimumNumber(
      "highestSale",
      0,
      "Highest Estimated Sales Amount Must be Greater than $0"
    );
    validateMaximumNumber(
      "highestSale",
      100000,
      "Highest Estimated Sales Amount Must be no more than $100000"
    );

    // Estimated Monthly Credit and Debit Card Sales
    validateMinimumNumber(
      "cardSales",
      0,
      "Estimated Monthly Credit and Debit Card Sales Must be Greater than 0"
    );
  }

  const runValidationFinancialInfo = () => {
    // Routing Number
    validateTextField("routingNumber", "Routing Number is Required");
            
    // Bank Name
    validateTextField("bankName", "Bank Name is Required");

    // Bank Account Number
    validateTextField(
      "bankAccountNumber",
      "Bank Account Number is Required"
    );

    // Confirm Bank Account
    validateTextField(
      "confirmBankAccountNumber",
      "Confirm Bank Account Number is Required"
    );
    validateTextMatch(
      "bankAccountNumber",
      "confirmBankAccountNumber",
      "Bank Account Numbers Must Match"
    );
  }

  const runValidationCustomPricing = () => {
    Object.keys(application.customPricing).forEach(fieldName => {
      validateCustomPricingField(fieldName, `Field is Required`);
    });

    validateTextField("soldProductTypes", "Type of Product(s)/Service(s) Sold is Required");

    if (!application.reprogram && hasTerminals(application) && application.customPricingType == 'Flat') {
      validateTextField("terminalShippingEmail", "Shipping Email is Required");
      validateTextField("terminalsBilling", "Billing is Required");
      validateTextField("terminalShipToType", "Shipping Type is Required");
      validateTextField("terminalShippingService", "Shipping Service is Required");

      validateRegEx(
        "terminalShippingEmail",
        "Shipping Email is an Incorrect Format",
        getFieldRegexp('email')
      );
    }
    validateTextField("depositTimeFrame", "Deposit Time Frame is Required");

    validateTextField(
      "merchantType",
      "Merchant Type is Required"
    );   
    validateTextField(
      "merchantLocation",
      "Merchant Location is Required"
    );   
  }

  const runValidationProcessingInfo = () => { 
    validateTextField(
      "processor",
      "Processor is Required"
    );
    validateTextField(
      "office",
      "Office is Required"
    );    
    validateTextField(
      "salesRepInfo",
      "Sales Rep is Required"
    );
  }

  const runValidationPaymentInfo = () => { 
    if (application.withoutPayment) return;
    
    validateTextField(
      "paymentStatementName",
      "Name on Bank Statement is Required"
    );
    validateTextField("paymentBankName", "Bank Name is Required");
    validateTextField(
      "paymentAccountNumber",
      "Account Number is Required"
    );
    validateTextField(
      "paymentRoutingNumber",
      "Routing Number is Required"
    );
    validateTextField("branchCity", "City is Required");
    validateTextField("branchState", "State is Required");
    validateMinimumCharLength(
      "branchState",
      2,
      "State Must be 2 Characters"
    );
    validateTextField("branchZip", "Zip Code is Required");
    validateTextLength("branchZip", 5, "Zip Code is an Incorrect Format");
  }

  const runValidationSign = () => { 
    if (application.agree === false) {
      isValid = false;
      errors.agree = "You must agree to the Terms and Conditions";
    }
    if (application.agreeAdditional === false) {
      isValid = false;
      errors.agreeAdditional = "You must agree to Equipment Placement Program";
    }
    if (signaturePad.isEmpty()) {
      isValid = false;
      errors.signature = "Signature is Required";
    }
  }
  if (application.isSubmitting) {
    switch (activeStep) {
      case 0:
        runValidationEmail();
        break;
      case 1:
        runValidationBusinessInfo();
        break;
      case 2:
        runValidationPrincipalInfo();
        break;
      case 3:
        runValidationAccountInfo();
        break;
      case 4:
        runValidationFinancialInfo();
        break;
      case 5:
        if (!application.link || application.link.paymentType !== "ACH")
          runValidationPaymentCartInfo();
        else
          runValidationPaymentInfo();
        break;
      case 6:
        runValidationProcessingInfo();
        break;
      case 7:
          runValidationCustomPricing();
        break;
      case 8:
        //addendums
        break;
      default:
        return { isValid: true, errors: {} };
    }
  }
  else if (application.repStarted) {
    switch (activeStep) {
      case 0:
        runValidationEmail();
        break;
      case 1:
        runValidationBusinessInfo();
        break;
      case 2:
        runValidationPrincipalInfo();
        break;
      case 3:
        runValidationAccountInfo();
        break;
      case 4:
        runValidationFinancialInfo();
        break;
      case 5:
        if (!application.link || application.link.paymentType !== "ACH")
          runValidationPaymentCartInfo();
        else
          runValidationPaymentInfo();
        break;
      case 6:
        runValidationSign();
        break;
      default:
        return { isValid: true, errors: {} };
    }
  } else if (application.link) {
    switch (activeStep) {
      case 0:
        runValidationEmail();
        break;
      case 1:
        runValidationBusinessInfo();
        break;
      case 2:
        runValidationPrincipalInfo();
        break;
      case 3:
        runValidationAccountInfo();
        break;
      case 4:
        runValidationFinancialInfo();
        break;
      case 5:
        if (!application.link || application.link.paymentType !== "ACH")
          runValidationPaymentCartInfo();
        else
          runValidationPaymentInfo();
        break;
      case 6:
        runValidationSign();
        break;
      default:
        return { isValid: true, errors: {} };
    }
  } else {
    switch (activeStep) {
      case 0:
        runValidationEmail();
        break;
      case 1:
        runValidationBusinessInfo();
        break;
      case 2:
        runValidationPrincipalInfo();
        break;
      case 3:
        runValidationAccountInfo();
        break;
      case 4:
        runValidationFinancialInfo();
        break;
      case 5:
        if (!application.link || application.link.paymentType !== "ACH")
          runValidationPaymentCartInfo();
        else
          runValidationPaymentInfo();
        break;
      case 6:
        runValidationSign();
        break;
      default:
        return { isValid: true, errors: {} };
    }
  }
  return { isValid, errors };
};
